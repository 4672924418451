// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PagesUI_view__-362U {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.PagesUI_loader__5gNfZ {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex: 1 1;\n}\n\n.PagesUI_edit__dKe\\+5, .PagesUI_delete__1z9dG {\n  cursor: pointer;\n  margin-top: 3px;\n}\n\n.PagesUI_actions__wOoco {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.PagesUI_divider__lbXlj {\n  width: 1px;\n  background-color: #C9C9C9;\n  height: 22px;\n  margin: 0 15px;\n}", "",{"version":3,"sources":["webpack://./src/pages/pages/styles/PagesUI.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAO;AACT;;AAEA;EACE,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,UAAU;EACV,yBAAyB;EACzB,YAAY;EACZ,cAAc;AAChB","sourcesContent":[".view {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.loader {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex: 1;\n}\n\n.edit, .delete {\n  cursor: pointer;\n  margin-top: 3px;\n}\n\n.actions {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.divider {\n  width: 1px;\n  background-color: #C9C9C9;\n  height: 22px;\n  margin: 0 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"view": "PagesUI_view__-362U",
	"loader": "PagesUI_loader__5gNfZ",
	"edit": "PagesUI_edit__dKe+5",
	"delete": "PagesUI_delete__1z9dG",
	"actions": "PagesUI_actions__wOoco",
	"divider": "PagesUI_divider__lbXlj"
};
export default ___CSS_LOADER_EXPORT___;
