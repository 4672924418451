// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CategoriesUI_view__NoKdP {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.CategoriesUI_loader__YN1ds {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex: 1 1;\n}\n\n.CategoriesUI_edit__FInM5, .CategoriesUI_delete__3SCOh {\n  cursor: pointer;\n  margin-top: 3px;\n}\n\n.CategoriesUI_actions__RRW8t {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.CategoriesUI_divider__GAKRz {\n  width: 1px;\n  background-color: #C9C9C9;\n  height: 22px;\n  margin: 0 15px;\n}\n\n.CategoriesUI_input__LlECF {\n  background-color: #FBFBFB;\n  border-color: #D2D2D2;\n  border-radius: 4px;\n  width: 300px;\n  margin-right: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/categories/styles/CategoriesUI.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAO;AACT;;AAEA;EACE,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,UAAU;EACV,yBAAyB;EACzB,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".view {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.loader {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex: 1;\n}\n\n.edit, .delete {\n  cursor: pointer;\n  margin-top: 3px;\n}\n\n.actions {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.divider {\n  width: 1px;\n  background-color: #C9C9C9;\n  height: 22px;\n  margin: 0 15px;\n}\n\n.input {\n  background-color: #FBFBFB;\n  border-color: #D2D2D2;\n  border-radius: 4px;\n  width: 300px;\n  margin-right: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"view": "CategoriesUI_view__NoKdP",
	"loader": "CategoriesUI_loader__YN1ds",
	"edit": "CategoriesUI_edit__FInM5",
	"delete": "CategoriesUI_delete__3SCOh",
	"actions": "CategoriesUI_actions__RRW8t",
	"divider": "CategoriesUI_divider__GAKRz",
	"input": "CategoriesUI_input__LlECF"
};
export default ___CSS_LOADER_EXPORT___;
