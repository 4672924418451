// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Close_close__9T5DW {\n  background-color: var(--green);\n  width: 30px;\n  height: 30px;\n  border-radius: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n\n.Close_x__3W3JM {\n  width: 50%;\n}", "",{"version":3,"sources":["webpack://./src/components/styles/Close.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".close {\n  background-color: var(--green);\n  width: 30px;\n  height: 30px;\n  border-radius: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n\n.x {\n  width: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"close": "Close_close__9T5DW",
	"x": "Close_x__3W3JM"
};
export default ___CSS_LOADER_EXPORT___;
