// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ClientsUI_view__sO3ei {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.ClientsUI_loader__2EyIj {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex: 1 1;\n}\n\n.ClientsUI_edit__n9gvx, .ClientsUI_delete__mNxsR {\n  cursor: pointer;\n  margin-top: 3px;\n}\n\n.ClientsUI_actions__D7BY8 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.ClientsUI_divider__WRoGt {\n  width: 1px;\n  background-color: #C9C9C9;\n  height: 22px;\n  margin: 0 15px;\n}\n\n.ClientsUI_watch__phoug {\n  color: var(--green);\n}\n\n.ClientsUI_eye__guWEt {\n  width: 20px;\n  height: 20px;\n}", "",{"version":3,"sources":["webpack://./src/pages/clients/styles/ClientsUI.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAO;AACT;;AAEA;EACE,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,UAAU;EACV,yBAAyB;EACzB,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".view {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.loader {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex: 1;\n}\n\n.edit, .delete {\n  cursor: pointer;\n  margin-top: 3px;\n}\n\n.actions {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.divider {\n  width: 1px;\n  background-color: #C9C9C9;\n  height: 22px;\n  margin: 0 15px;\n}\n\n.watch {\n  color: var(--green);\n}\n\n.eye {\n  width: 20px;\n  height: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"view": "ClientsUI_view__sO3ei",
	"loader": "ClientsUI_loader__2EyIj",
	"edit": "ClientsUI_edit__n9gvx",
	"delete": "ClientsUI_delete__mNxsR",
	"actions": "ClientsUI_actions__D7BY8",
	"divider": "ClientsUI_divider__WRoGt",
	"watch": "ClientsUI_watch__phoug",
	"eye": "ClientsUI_eye__guWEt"
};
export default ___CSS_LOADER_EXPORT___;
