// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VehiclesUI_view__slpL6 {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.VehiclesUI_loader__r0sxE {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex: 1 1;\n}\n\n.VehiclesUI_edit__V8E2n, .VehiclesUI_delete__xflWY {\n  cursor: pointer;\n  margin-top: 3px;\n}\n\n.VehiclesUI_actions__T0ISm {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.VehiclesUI_divider__eMR-J {\n  width: 1px;\n  background-color: #C9C9C9;\n  height: 22px;\n  margin: 0 15px;\n}", "",{"version":3,"sources":["webpack://./src/pages/vehicles/styles/VehiclesUI.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAO;AACT;;AAEA;EACE,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,UAAU;EACV,yBAAyB;EACzB,YAAY;EACZ,cAAc;AAChB","sourcesContent":[".view {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.loader {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex: 1;\n}\n\n.edit, .delete {\n  cursor: pointer;\n  margin-top: 3px;\n}\n\n.actions {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.divider {\n  width: 1px;\n  background-color: #C9C9C9;\n  height: 22px;\n  margin: 0 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"view": "VehiclesUI_view__slpL6",
	"loader": "VehiclesUI_loader__r0sxE",
	"edit": "VehiclesUI_edit__V8E2n",
	"delete": "VehiclesUI_delete__xflWY",
	"actions": "VehiclesUI_actions__T0ISm",
	"divider": "VehiclesUI_divider__eMR-J"
};
export default ___CSS_LOADER_EXPORT___;
