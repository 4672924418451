// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConfirmDriver_header__yBU1R {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 5px;\n  border-bottom: 1px solid #BEBEBE;\n  padding-bottom: 10px;\n}\n\n.ConfirmDriver_title__pABry {\n  font-family: Bree;\n  font-weight: 700;\n  color: var(--darkGray);\n  font-size: 20px;\n}\n\n.ConfirmDriver_body__ZS70j {\n  text-align: center;\n  color: var(--darkGray);\n  margin: 40px 0;\n}\n\n.ConfirmDriver_body__ZS70j p {\n  display: block;\n  margin-bottom: 30px;\n}\n\n.ConfirmDriver_action__EEPEU {\n  display: flex;\n  justify-content: center;\n}\n\n.ConfirmDriver_submit__KFJoh {\n  font-weight: 500;\n  font-size: 12px;\n  width: 300px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/monitor/styles/ConfirmDriver.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;EAClB,gCAAgC;EAChC,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,YAAY;AACd","sourcesContent":[".header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 5px;\n  border-bottom: 1px solid #BEBEBE;\n  padding-bottom: 10px;\n}\n\n.title {\n  font-family: Bree;\n  font-weight: 700;\n  color: var(--darkGray);\n  font-size: 20px;\n}\n\n.body {\n  text-align: center;\n  color: var(--darkGray);\n  margin: 40px 0;\n}\n\n.body p {\n  display: block;\n  margin-bottom: 30px;\n}\n\n.action {\n  display: flex;\n  justify-content: center;\n}\n\n.submit {\n  font-weight: 500;\n  font-size: 12px;\n  width: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "ConfirmDriver_header__yBU1R",
	"title": "ConfirmDriver_title__pABry",
	"body": "ConfirmDriver_body__ZS70j",
	"action": "ConfirmDriver_action__EEPEU",
	"submit": "ConfirmDriver_submit__KFJoh"
};
export default ___CSS_LOADER_EXPORT___;
