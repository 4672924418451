// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderList_header__N7jbf {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 40px;\n}\n\n.HeaderList_titleWrap__Yb3y\\+ {\n  display: flex;\n  align-items: center;\n}\n\n.HeaderList_title__nfYZL {\n  font-weight: 700;\n  font-family: Bree;\n  color: var(--green);\n  font-size: 30px;\n  line-height: 25px;\n}\n\n.HeaderList_backWrap__OefAf {\n  display: flex;\n  width: 32px;\n  height: 32px;\n  align-items: center;\n  justify-content: center;\n  background-color: var(--green);\n  border-radius: 100%;\n  color: white;\n  margin-right: 15px;\n}", "",{"version":3,"sources":["webpack://./src/components/styles/HeaderList.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;EACvB,8BAA8B;EAC9B,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 40px;\n}\n\n.titleWrap {\n  display: flex;\n  align-items: center;\n}\n\n.title {\n  font-weight: 700;\n  font-family: Bree;\n  color: var(--green);\n  font-size: 30px;\n  line-height: 25px;\n}\n\n.backWrap {\n  display: flex;\n  width: 32px;\n  height: 32px;\n  align-items: center;\n  justify-content: center;\n  background-color: var(--green);\n  border-radius: 100%;\n  color: white;\n  margin-right: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "HeaderList_header__N7jbf",
	"titleWrap": "HeaderList_titleWrap__Yb3y+",
	"title": "HeaderList_title__nfYZL",
	"backWrap": "HeaderList_backWrap__OefAf"
};
export default ___CSS_LOADER_EXPORT___;
