// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BranchesUI_view__ontzt {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.BranchesUI_loader__wMJ32 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex: 1 1;\n}\n\n.BranchesUI_edit__2Hfx\\+ {\n  cursor: pointer;\n}\n\n.BranchesUI_hoursBtn__WG4q6 {\n  margin-right: 20px;\n  width: 200px;\n  font-weight: 500;\n  border-radius: 6px;\n  font-size: 14px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/branches/styles/BranchesUI.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAO;AACT;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".view {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.loader {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex: 1;\n}\n\n.edit {\n  cursor: pointer;\n}\n\n.hoursBtn {\n  margin-right: 20px;\n  width: 200px;\n  font-weight: 500;\n  border-radius: 6px;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"view": "BranchesUI_view__ontzt",
	"loader": "BranchesUI_loader__wMJ32",
	"edit": "BranchesUI_edit__2Hfx+",
	"hoursBtn": "BranchesUI_hoursBtn__WG4q6"
};
export default ___CSS_LOADER_EXPORT___;
