import {useState} from 'react';
import {Button, Input, message} from 'antd';
import Close from '../../../components/Close';
import styles from '../styles/Password.module.css';

const Password = ({close, changePassword}) => {
  const [sending, handleSending] = useState(false);
  const [password, handlePassword] = useState('');

  const handleNewPassword = async () => {
    if (password.length) {
      handleSending(true);
      await changePassword(parseFloat(password));
      handleSending(false);
      close();
    } else {
      message.error('Ingresa una contraseña');
    }
  };

  const newPassword = async e => {
    handlePassword(e.target.value);
  };

  return (
    <div>
      <div className={styles.header}>
        <span className={styles.title}>
          Modificar contraseña
        </span>
        <Close action={close} />
      </div>
      <div className={styles.body}>
        <Input
          className={styles.input}
          size="large"
          value={password}
          onChange={newPassword}
        />
      </div>
      <div className={styles.action}>
        <Button
          loading={sending}
          onClick={handleNewPassword}
          size="large"
          type="primary"
          className={styles.submit}>
          CAMBIAR CONTRASEÑA
        </Button>
      </div>
    </div>
  );
};

export default Password;
