// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProductCard_card__yfN6w {\n  background-color: white;\n  box-shadow: 0 2px 15px rgba(100, 100, 100, 0.15);\n  padding: 20px;\n  border-radius: 10px;\n  display: flex;\n  margin-bottom: 20px;\n  cursor: pointer;\n}\n\n.ProductCard_info__6Q2hF {\n  flex: 1 1;\n  padding-right: 20px;\n  margin-right: 20px;\n  border-right: 1px solid #DCDCDC;\n}\n\n.ProductCard_item__ahlsS {\n  margin-bottom: 15px;\n}\n\n.ProductCard_item__ahlsS:last-child {\n  margin-bottom: 0;\n}\n\n.ProductCard_label__i\\+Bwb {\n  display: block;\n  color: var(--darkGray);\n  font-size: 12px;\n}\n\n.ProductCard_value__C9dXS {\n  font-weight: 500;\n  color: #444444;\n}\n\n.ProductCard_total__uFBg\\+ {\n  font-weight: 700;\n  color: var(--green);\n  font-size: 18px;\n}", "",{"version":3,"sources":["webpack://./src/pages/monitor/styles/ProductCard.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,gDAAgD;EAChD,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,SAAO;EACP,mBAAmB;EACnB,kBAAkB;EAClB,+BAA+B;AACjC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".card {\n  background-color: white;\n  box-shadow: 0 2px 15px rgba(100, 100, 100, 0.15);\n  padding: 20px;\n  border-radius: 10px;\n  display: flex;\n  margin-bottom: 20px;\n  cursor: pointer;\n}\n\n.info {\n  flex: 1;\n  padding-right: 20px;\n  margin-right: 20px;\n  border-right: 1px solid #DCDCDC;\n}\n\n.item {\n  margin-bottom: 15px;\n}\n\n.item:last-child {\n  margin-bottom: 0;\n}\n\n.label {\n  display: block;\n  color: var(--darkGray);\n  font-size: 12px;\n}\n\n.value {\n  font-weight: 500;\n  color: #444444;\n}\n\n.total {\n  font-weight: 700;\n  color: var(--green);\n  font-size: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "ProductCard_card__yfN6w",
	"info": "ProductCard_info__6Q2hF",
	"item": "ProductCard_item__ahlsS",
	"label": "ProductCard_label__i+Bwb",
	"value": "ProductCard_value__C9dXS",
	"total": "ProductCard_total__uFBg+"
};
export default ___CSS_LOADER_EXPORT___;
