// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CardSection_card__ZlM-U {\n  background-color: white;\n  box-shadow: 0 2px 15px rgba(100, 100, 100, 0.15);\n  width: 231px;\n  border-radius: 6px;\n  height: 179px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  margin-right: 40px;\n  margin-bottom: 40px;\n}\n\n.CardSection_label__tgovP {\n  font-weight: 500;\n  display: block;\n  margin-top: 10px;\n  color: var(--darkGray);\n}\n\n.CardSection_image__5shI1 {\n  width: 60px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/styles/CardSection.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,gDAAgD;EAChD,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".card {\n  background-color: white;\n  box-shadow: 0 2px 15px rgba(100, 100, 100, 0.15);\n  width: 231px;\n  border-radius: 6px;\n  height: 179px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  margin-right: 40px;\n  margin-bottom: 40px;\n}\n\n.label {\n  font-weight: 500;\n  display: block;\n  margin-top: 10px;\n  color: var(--darkGray);\n}\n\n.image {\n  width: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "CardSection_card__ZlM-U",
	"label": "CardSection_label__tgovP",
	"image": "CardSection_image__5shI1"
};
export default ___CSS_LOADER_EXPORT___;
